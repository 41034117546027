import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p className="text-4xl text-teal">
      Hello, I'm Stefanie. I lead cross-functional teams to define, design,
      build, and ship impactful products. The core of my work is collaborating
      and problem solving, and leading teams by cultivating trust and
      creativity.
    </p>
    <p className="text-2xl text-teal mt-12">
      I live in Toronto, Ontario, Canada, currently working as a product manager
      at{" "}
      <a
        href="https://www.twg.io"
        target="_blank"
        class="underline hover:text-tomat"
      >
        TWG
      </a>
      . Prior to becoming a product manager, I ran my own business, worked as a
      photo retoucher, taught at a local college, and managed the production of
      an independently-published contemporary art magazine.
    </p>
    <p className="text-2xl text-teal mt-6">
      My hobbies include reading fiction, discovering new music, and finding
      ways to fit more plants into my apartment.
    </p>
    <p className="text-2xl text-teal mt-6">
      If you’d like to reach out, you can send me an{" "}
      <a
        href="mailto:stefaniefiore@gmail.com"
        class="underline hover:text-tomat"
      >
        email
      </a>{" "}
      or find me on{" "}
      <a
        href="https://www.linkedin.com/in/stefaniefiore/"
        target="_blank"
        class="underline hover:text-tomat"
      >
        LinkedIn
      </a>
      .
    </p>
    {/* <p className="uppercase text-teal mt-20 font-bold">
      Writing & Other Things
    </p>
    <p className="text-3xl text-teal mt-6">
      <a
        href="https://www.google.com"
        target="_blank"
        class="underline hover:text-tomato"
      >
        Creating an online film festival in four weeks
      </a>
    </p>
    <p className="text-3xl text-teal mt-6">
      <a
        href="https://www.google.com"
        target="_blank"
        class="underline hover:text-tomato"
      >
        Top Tips for Retrospectives that don't suck
      </a>
    </p>
    <p className="text-3xl text-teal mt-6">
      <a
        href="https://www.google.com"
        target="_blank"
        class="underline hover:text-tomato"
      >
        Plant Matchmaker Quiz
      </a>
    </p> */}
    <div></div>
  </Layout>
)

export default IndexPage
